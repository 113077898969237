import { RiVipCrownLine } from 'react-icons/ri'; // todo: replace with iconify
import { AiOutlineUsergroupAdd } from 'react-icons/ai'; // todo: replace with iconify
// @miui
import {
  CampaignOutlined,
  CardGiftcard,
  DashboardOutlined,
  History,
  LoyaltyOutlined,
  PeopleOutlined,
  SettingsOutlined,
  SummarizeOutlined,
  EditNoteOutlined,
} from '@mui/icons-material';
// components
import Iconify from '../../../components/iconify';
// routes
import {
  PATH_GIFT_CARD_PROGRAM,
  PATH_MAIN,
  PATH_REWARDS_PROGRAM,
  PATH_SETTINGS,
  PATH_STORE_CREDIT_PROGRAM,
} from '../../../routes/paths';

// ----------------------------------------------------------------------

const navConfig = [
  {
    subheader: '',
    items: [
      {
        title: 'getting_started_progress',
        path: PATH_MAIN.getting_started,
        icon: <EditNoteOutlined />,
        permissions: ['allow_getting_started', 'main_business'],
        roles: ['owner'],
      },
    ],
  },
  // MAIN
  // ----------------------------------------------------------------------
  {
    subheader: 'main',
    items: [
      {
        title: 'dashboard',
        path: PATH_MAIN.dashboard,
        icon: <DashboardOutlined />,
        roles: ['owner', 'admin'],
        permissions: ['dashboard'],
      },
      {
        title: 'members',
        path: PATH_MAIN.members,
        icon: <PeopleOutlined />,
        roles: ['owner', 'admin', 'manager'],
        permissions: ['members'],
      },
      {
        title: 'campaigns',
        path: PATH_MAIN.campaigns,
        icon: <CampaignOutlined />,
        roles: ['owner', 'admin', 'manager', 'marketing'],
        permissions: ['campaigns'],
      },
      {
        title: 'notifications',
        path: PATH_MAIN.notifications,
        icon: <Iconify icon="mdi:push-notification-outline" />,
        permissions: ['main_business'],
        roles: ['owner', 'admin'],
        restrictedPlans: ['basic'],
      },
    ],
    restrictedPlans: ['basic', 'none'],
  },

  // REWARDS PROGRAM
  // ----------------------------------------------------------------------
  {
    subheader: 'rewards_program',
    items: [
      {
        title: 'point_rules',
        path: PATH_REWARDS_PROGRAM.points.earning,
        icon: <Iconify icon="octicon:feed-star-16" size={24} />,
        permissions: ['point_rules'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'reward_levels',
        path: PATH_REWARDS_PROGRAM.points.redeeming,
        icon: <LoyaltyOutlined />,
        permissions: ['reward_levels'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'punch_cards_program',
        path: PATH_REWARDS_PROGRAM.punch_cards.root,
        icon: <Iconify icon="carbon:circle-filled" size={24} />,
        permissions: ['reward_levels'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'retention_autopilot',
        path: PATH_REWARDS_PROGRAM.retention_autopilots.root,
        icon: <Iconify icon="iconoir:trophy" width={24} />,
        permissions: ['retention_autopilot'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'vouchers',
        path: PATH_REWARDS_PROGRAM.vouchers.root,
        icon: <Iconify icon="mdi:voucher-outline" width={24} />,
        permissions: ['vouchers'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'vip_program',
        path: PATH_REWARDS_PROGRAM.vip.root,
        icon: <RiVipCrownLine size={24} />,
        permissions: ['vip_program'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'referral_program',
        path: PATH_REWARDS_PROGRAM.referrals.root,
        icon: <AiOutlineUsergroupAdd size={24} />,
        permissions: ['referral_program'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'activity',
        path: PATH_REWARDS_PROGRAM.activity,
        icon: <History size={24} />,
        permissions: ['activity'],
        roles: ['owner', 'admin', 'manager'],
      },
      {
        title: 'reports',
        path: PATH_REWARDS_PROGRAM.reports,
        icon: <SummarizeOutlined size={24} />,
        permissions: ['reports'],
        roles: ['owner', 'admin', 'manager'],
      },
    ],
    permissions: ['rewards_program'],
    roles: ['owner', 'admin', 'manager'],
  },

  // GIFT CARD PROGRAM
  // ----------------------------------------------------------------------
  {
    subheader: 'gift_card_program',
    items: [
      {
        title: 'gift_cards',
        path: PATH_GIFT_CARD_PROGRAM.gift_cards,
        icon: <CardGiftcard size={24} />,
      },
      {
        title: 'gift_card_transactions',
        path: PATH_GIFT_CARD_PROGRAM.gift_card_transactions,
        icon: <Iconify icon="uil:transaction" />,
      },
      {
        title: 'reconciliation_report',
        path: PATH_GIFT_CARD_PROGRAM.gift_card_reconciliation_report,
        icon: <Iconify icon="mdi:file-report-outline" />,
      },
      {
        title: 'gift_items',
        path: PATH_GIFT_CARD_PROGRAM.gift_items,
        icon: <Iconify icon="material-symbols:inventory-2-outline-rounded" />,
        permissions: ['main_business'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'gift_card_setup',
        path: PATH_GIFT_CARD_PROGRAM.setup,
        icon: <SettingsOutlined size={24} />,
        permissions: ['main_business'],
        roles: ['owner', 'admin'],
      },
    ],
    permissions: ['gift_program'],
    roles: ['owner', 'admin', 'manager'],
  },

  // STORE CREDIT PROGRAM
  // ----------------------------------------------------------------------
  {
    subheader: 'store_credit_program',
    items: [
      {
        title: 'store_credit_cards',
        path: PATH_STORE_CREDIT_PROGRAM.store_credit_cards,
        icon: <Iconify icon="mdi:wallet-outline" />,
      },
      {
        title: 'store_credit_card_transactions',
        path: PATH_STORE_CREDIT_PROGRAM.store_credit_card_transactions,
        icon: <Iconify icon="uil:transaction" />,
      },
      {
        title: 'cashback_program',
        path: PATH_STORE_CREDIT_PROGRAM.cashback_program,
        icon: <Iconify icon="streamline:subscription-cashflow-solid" size={24} />,
        permissions: ['main_business', 'cashback_program'],
        roles: ['owner', 'admin'],
      },
      {
        title: 'store_credit_setup',
        path: PATH_STORE_CREDIT_PROGRAM.setup,
        icon: <SettingsOutlined size={24} />,
        permissions: ['main_business'],
        roles: ['owner', 'admin'],
      },
    ],
    permissions: ['store_credit_program'],
    roles: ['owner', 'admin', 'manager'],
  },

  // SETTINGS
  // ----------------------------------------------------------------------
  {
    subheader: 'settings',
    items: [
      {
        title: 'wallet_pass',
        path: PATH_SETTINGS.wallet_pass,
        icon: <Iconify icon="ic:round-wallet" />,
        permissions: ['main_business'],
        roles: ['owner', 'admin'],
        restrictedPlans: ['none'],
      },
      {
        title: 'member_site',
        path: PATH_SETTINGS.member_site,
        icon: <Iconify icon="fluent-mdl2:live-site" />,
        permissions: ['main_business'],
        roles: ['owner', 'admin'],
        restrictedPlans: ['basic', 'none'],
      },
      {
        title: 'manage_users',
        path: PATH_SETTINGS.manage_user,
        icon: <Iconify icon="carbon:id-management" />,
        permissions: ['manager_user'],
        roles: ['owner', 'admin'],
        restrictedPlans: ['none'],
      },
      {
        title: 'account',
        path: PATH_SETTINGS.account,
        icon: <Iconify icon="gg:profile" />,
      },
      // {
      //   title: 'locations',
      //   path: PATH_SETTINGS.locations,
      //   icon: <Iconify icon="mdi:store-plus" />,
      //   permissions: ['locations'],
      //   roles: ['owner', 'admin'],
      // },
      {
        title: 'billing',
        path: PATH_SETTINGS.billing,
        icon: <Iconify icon="vaadin:invoice" />,
        permissions: ['manage_billing'],
        roles: ['owner'],
      },
    ],
  },
];

export default navConfig;
